import React from "react"
import { ThemeIcon, Container, createStyles, Flex, List, rem, Text, Title } from "@mantine/core"
import { Image } from "../../shared/ui"
import { IconInfinity } from "@tabler/icons-react"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 130,
    paddingBottom: 130,
    position: "relative",
    backgroundColor: "#eeebe3",
    zIndex: 0,

    [theme.fn.smallerThan(1280)]: {
      paddingTop: 96,
      paddingBottom: 96,
    },

    [theme.fn.smallerThan(768)]: {
      paddingTop: 48,
      paddingBottom: 48,
    },
  },

  highlight: {
    position: "relative",
    color: theme.white,
    background: theme.fn.linearGradient(90, theme.colors.teal[7], theme.colors.green[7]),
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
    lineHeight: 1.8,
  },

  bg: {
    position: "absolute",
    width: "65%",
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.black,
    backgroundImage: "repeating-linear-gradient(150deg,rgba(255,255,255,.03),rgba(255,255,255,.03) 1px,transparent 0,transparent 41px),repeating-linear-gradient(-150deg,rgba(255,255,255,.03),rgba(255,255,255,.03) 1px,transparent 0,transparent 41px)",
    backgroundSize: "82px 47px",
    backgroundAttachment: "fixed",
    backgroundPosition: "top center",
    zIndex: 1,

    [theme.fn.smallerThan(768)]: {
      width: "100%",
    },
  },

  title: {
    fontSize: 48,
    lineHeight: 1.5,
    marginBottom: 48,

    [theme.fn.smallerThan(1280)]: {
      fontSize: 36,
    },

    [theme.fn.smallerThan(1024)]: {
      fontSize: 28,
      marginBottom: 32,
    },

    [theme.fn.smallerThan(425)]: {
      lineHeight: 1.7,
      fontSize: 24,
    },
  },

  text: {
    fontWeight: 700,
    fontSize: 20,
    color: "white",

    [theme.fn.smallerThan(1280)]: {
      fontSize: 18,
      fontWeight: 500,
    },

    [theme.fn.smallerThan(1024)]: {
      fontSize: 16,
    },

    [theme.fn.smallerThan(425)]: {
      fontWeight: 400,
    },
  },

  mainFlex: {
    position: "relative",
    zIndex: 2,
    color: "white",
    gap: 48,

    [theme.fn.smallerThan(1024)]: {
      gap: 24,
    },
  },

  icon: {
    width: 48,
    height: 48,
    minHeight: 48,
    minWidth: 48,

    [theme.fn.smallerThan(1024)]: {
      width: 36,
      height: 36,
      minHeight: 36,
      minWidth: 36,
      fontSize: 12,

      "svg": {
        width: 18,
        height: 18,
      },
    },
  },

  imagesBox: {
    gap: 16,
    width: "70%",
    display: "flex",

    [theme.fn.smallerThan(960)]: {
      width: "50%",
    },

    [theme.fn.smallerThan(768)]: {
      display: "none",
    },
  },

  desc: {
    marginBottom: 24,

    [theme.fn.smallerThan(425)]: {
      marginBottom: 16,
    },
  },
}))

export function AboutTraining(): React.JSX.Element {
  const { classes } = useStyles()
  const matched = useMediaQuery(`(min-width: 960px)`)

  return (
    <section className={classes.wrapper}>
      <Container size={1440}>
        <div className={classes.bg}/>
        <Flex className={classes.mainFlex}>
          <Flex align="center" className={classes.imagesBox}>
            <Image
              src="assets/dose-juice-sTPy-oeA3h0-unsplash.jpg"
              ratio="3/4"
              sx={{ borderRadius: 8, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", top: matched ? -50 : 0 }}
            />
            {matched && (
              <Image
                ratio="3/4"
                src="assets/jennifer-burk-ECXB0YAZ_zU-unsplash.jpg"
                sx={{
                  borderRadius: 8,
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  top: 50,
                }}
              />
            )}
          </Flex>

          <Flex direction="column">
            <Text c="dimmed" className={classes.desc}>Чем мы отличаемся от остальных</Text>

            <Title className={classes.title}>Обучающий курс<br/>
              <span className={classes.highlight}>Нутрициолог. Научный подход</span>
              &nbsp;это
            </Title>

            <List
              spacing="xl"
              center
            >
              <List.Item
                icon={
                  <ThemeIcon radius="xl" className={classes.icon} variant="outline" color="teal.7">
                    100+
                  </ThemeIcon>
                }
              ><Text className={classes.text}>Более 100 лекций и практических заданий</Text></List.Item>
              <List.Item
                icon={
                  <ThemeIcon radius="xl" className={classes.icon} variant="outline" color="teal.7">
                    270
                  </ThemeIcon>
                }
              ><Text className={classes.text}>270 часов образовательного процесса в соответствии с международными
                стандартами</Text></List.Item>
              <List.Item
                icon={
                  <ThemeIcon radius="xl" className={classes.icon} variant="outline" color="teal.7">
                    10+
                  </ThemeIcon>
                }
              ><Text className={classes.text}>Свыше 10 учебных модулей</Text></List.Item>
              <List.Item
                icon={
                  <ThemeIcon radius="xl" className={classes.icon} variant="outline" color="teal.7">
                    30+
                  </ThemeIcon>
                }
              ><Text className={classes.text}>Более 30 экспертов в сфере нутрициологии и доказательной
                медицины</Text></List.Item>
              <List.Item
                icon={
                  <ThemeIcon radius="xl" className={classes.icon} variant="outline" color="teal.7">
                    180
                  </ThemeIcon>
                }
              ><Text className={classes.text}>180 дней беспрерывного обучения с лучшими преподавателями России и
                СНГ</Text></List.Item>
              <List.Item
                icon={
                  <ThemeIcon radius="xl" className={classes.icon} variant="outline" color="teal.7">
                    <IconInfinity/>
                  </ThemeIcon>
                }
              >
                <Text className={classes.text}>Бессрочный доступ к материалам курса</Text></List.Item>
            </List>

          </Flex>
        </Flex>

      </Container>
    </section>
  )
}
