export const caruselItems = [
  {
    id: "1",
    image: "assets/review/1.jpg",
    title: "Татьяна Горбачёва",
    category: "Фитнес тренер",
  },
  {
    id: "2",
    image: "assets/review/2.jpg",
    title: "Алёна Брежнева",
    category: "Нутрициолог, научный подход",
  },
  {
    id: "3",
    image: "assets/review/3.jpg",
    title: "Карина Вотякова",
    category: "Общая нутрициология",
  },
  {
    id: "4",
    image: "assets/review/4.jpg",
    title: "Юлия Решетило",
    category: "Консультирование",
  },
  {
    id: "5",
    image: "assets/review/5.jpg",
    title: "Анастасия Юрпалова",
    category: "Консультирование",
  },
]
