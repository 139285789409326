import React from "react"
import {
  Container,
  createStyles,
  Title,
  Flex,
  Text,
  Center,
  Button,
  SimpleGrid,
  Badge,
  Group,
  Stack,
  Box,
  ButtonProps,
  MantineColor,
} from "@mantine/core"
import {
  IconCalendar,
  IconPlayerPlay,
  IconCertificate,
  TablerIconsProps,
  IconBrandTinder,
  IconStarFilled,
} from "@tabler/icons-react"
import { FormModalStore } from "../../app/FormModalStore"
import { Link } from "react-scroll"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    zIndex: 0,
    overflow: "hidden",
    backgroundColor: theme.colors.green[0],
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    marginBottom: 64,
    fontSize: 48,
    lineHeight: 1.6,

    [theme.fn.smallerThan("md")]: {
      textAlign: "center",
      marginBottom: 48,
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      marginBottom: 24,
    },
  },

  grid: {
    paddingLeft: 48,
    paddingRight: 48,

    [theme.fn.smallerThan("lg")]: {
      paddingLeft: 96,
      paddingRight: 96,
    },

    [theme.fn.smallerThan(900)]: {
      paddingLeft: 48,
      paddingRight: 48,
    },

    [theme.fn.smallerThan(830)]: {
      padding: 0,
    },
  },

  card: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    overflow: "hidden",
    borderRadius: theme.radius.lg,
    width: "100%",
    boxShadow: theme.shadows.xl,

    [theme.fn.smallerThan(700)]: {
      maxWidth: 366,
      margin: "0 auto",
    },

    "&::after": {
      content: "''",
      backgroundImage: "url(assets/prices/001.jpg)",
      backgroundPosition: "top right",
      backgroundSize: "cover",
      position: "absolute",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      zIndex: -2,
    },

    "&::before": {
      content: "''",
      backgroundColor: theme.black,
      position: "absolute",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      opacity: 0.7,
      zIndex: -1,
    },
  },

  cardTop: {
    flexGrow: 1,
    gap: 16,
    padding: 20,

    [theme.fn.smallerThan("md")]: {
      padding: 16,
    },
  },

  cardTitle: {
    maxWidth: "80%",
    fontSize: 28,
    lineHeight: 1.15,
    fontWeight: 700,
    color: theme.white,
  },

  cardBottom: {
    borderTop: `1px solid ${theme.colors.gray[6]}`,
    marginTop: "auto",
    padding: 20,
    gap: 16,

    [theme.fn.smallerThan("md")]: {
      padding: 16,
    },
  },

  cardGridItem: {
    color: theme.white,
    gap: 8,
  },

  discount: {
    color: theme.colors.gray[5],

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "row-reverse",
      alignItems: "center",
      gap: 16,
    },
  },

  prices: {
    [theme.fn.smallerThan("xs")]: {
      alignItems: "flex-end",
      justifyContent: "center",
      flexDirection: "column",
    },
  },

  price: {
    fontSize: 32,
    fontWeight: 700,
    color: theme.white,

    [theme.fn.smallerThan("md")]: {
      fontSize: 28,
    },
  },
}))

type Card = {
  cover: string
  title: string
  tags: Array<{
    text: string
    icon?: (props: TablerIconsProps) => JSX.Element
  }>
  gridItems: Array<{
    text: string
    icon: (props: TablerIconsProps) => JSX.Element
  }>
  prices: {
    old?: string
    current: string
    start?: string
    discount?: number
    installment?: boolean
  }
  button: {
    text: string
    target: string
    color?: MantineColor
  }
}

const data: Array<Card> = [
  {
    cover: "assets/prices/001.jpg",
    title: "Профессия Нутрициолог: полная оплата",
    tags: [
      {
        icon: IconStarFilled,
        text: "Лучшее предложение",
      },
    ],
    gridItems: [
      {
        text: "6 месяцев",
        icon: IconCalendar,
      },
      {
        text: "онлайн",
        icon: IconPlayerPlay,
      },
      {
        text: "удостоверение",
        icon: IconCertificate,
      },
      {
        text: "14 декабря",
        icon: IconBrandTinder,
      },
    ],
    prices: {
      old: "75 000",
      current: "65 000",
      start: "14 декабря",
      discount: 13,
    },
    button: {
      text: "Начать обучение",
      target: "Профессия: Нутрициолог. Блок: Стоимость обучения. Кнопка: Полная оплата",
    },
  },
  {
    cover: "assets/prices/002.jpg",
    title: "Профессия Нутрициолог: беспроцентная рассрочка",
    tags: [
      {
        text: "В рассрочку",
      },
      {
        text: "6 месяцев",
      },
    ],
    gridItems: [
      {
        text: "6 месяцев",
        icon: IconCalendar,
      },
      {
        text: "онлайн",
        icon: IconPlayerPlay,
      },
      {
        text: "удостоверение",
        icon: IconCertificate,
      },
      {
        text: "14 декабря",
        icon: IconBrandTinder,
      },
    ],
    prices: {
      old: "12 500",
      current: "11 666",
      start: "14 декабря",
      discount: 7,
      installment: true,
    },
    button: {
      text: "Оставить заявку",
      target: "Профессия: Нутрициолог. Блок: Стоимость обучения. Кнопка: Беспроцентная рассрочка на 6 мес.",
      color: "dark",
    },
  },
  {
    cover: "assets/prices/003.jpg",
    title: "Профессия Нутрициолог: самостоятельное изучение",
    tags: [
      {
        text: "Самостоятельно",
      },
    ],
    gridItems: [
      {
        text: "бессрочно",
        icon: IconCalendar,
      },
      {
        text: "онлайн",
        icon: IconPlayerPlay,
      },
    ],
    prices: {
      current: "55 000",
    },
    button: {
      text: "В своем темпе",
      target: "Профессия: Нутрициолог. Блок: Стоимость обучения. Кнопка: Самостоятельное изучение",
      color: "gray",
    },
  },
]

function PriceCard(card: Card) {
  const { classes } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)
  const xs = useMediaQuery(`(max-width: 576px)`)

  const offset = md ? -48 : xs ? -36 : -72
  const onClick = () => {
    FormModalStore.setData({ target: card.button.target })
  }

  const gradientProps: ButtonProps = {
    variant: "gradient",
    gradient: { from: "teal.7", to: "green.7", deg: 90 },
  }

  const colorProps: ButtonProps = {
    color: card.button.color,
  }

  const btnProps = card.button.color ? colorProps : gradientProps

  return (
    <Box
      sx={{
        "&::after": {
          backgroundImage: `url(${card.cover})`,
        },
      }}
      className={classes.card}
    >
      <Flex direction="column" className={classes.cardTop}>
        <Group>
          {card.tags.map((tag, index) => (
            <Badge
              key={index}
              leftSection={tag.icon ?
                <tag.icon style={{ position: "relative", color: "#FFA903", top: 1 }} size={20}/> : undefined}
              variant="filled"
              styles={(theme) => ({
                root: {
                  height: 24,
                  fontWeight: 500,
                  fontSize: 14,
                  textTransform: "none",
                  color: theme.colors.gray[8],
                  borderColor: theme.white,
                  backgroundColor: theme.white,
                },
              })}
            >
              {tag.text}
            </Badge>
          ))}
        </Group>
        <Text mb="auto" className={classes.cardTitle}>{card.title}</Text>
        <Button
          my={16}
          styles={(theme) => ({
            root: {
              width: "fit-content",
              fontWeight: 700,
              backgroundColor: "transparent",
              border: `2px solid ${theme.white}`,
              "&:not([data-disabled])": theme.fn.hover({
                borderColor: theme.fn.darken(theme.white, 0.1),
                backgroundColor: "transparent",
              }),
            },
          })}
          to={"program"}
          smooth={true}
          duration={1000}
          component={Link}
          offset={offset}
        >
          Подробнее о программе
        </Button>

        <SimpleGrid mih={65.6} cols={2}>
          {card.gridItems.map((el, index) => (
            <Flex key={index} className={classes.cardGridItem}>
              <el.icon/>
              <Text>{el.text}</Text>
            </Flex>
          ))}
        </SimpleGrid>

      </Flex>
      <Flex direction="column" className={classes.cardBottom}>
        <Flex align="center" justify="space-between" className={classes.prices}>
          {card.prices.old && (
            <Stack spacing={0} className={classes.discount}>
              <Text fz={20} strikethrough>{card.prices.old} &#8381;</Text>
              <Text fz={14}>{`До ${card.prices.start}`}</Text>
            </Stack>
          )}
          <Stack ml="auto" spacing={0}>
            <Text className={classes.price}>{card.prices.current} &#8381; {card.prices.installment &&
              <Text fz={24} span>/ мес</Text>}</Text>
          </Stack>
        </Flex>
        <Button
          radius="md"
          size="md"
          onClick={onClick}
          {...btnProps}
        >
          {card.button.text}
        </Button>
      </Flex>
    </Box>
  )
}

export function Prices(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <section id="prices" className={classes.wrapper}>
      <Container size={1320} sx={{ zIndex: 1 }}>
        <Title className={classes.title}>
          Выберите{" "}
          <Text
            span
            variant="gradient"
            gradient={{
              from: "teal.7",
              to: "green.7",
              deg: 105,
            }}
          >
            свой вариант{" "}
          </Text>
          обучения на курсе
        </Title>

        <SimpleGrid
          className={classes.grid}
          cols={3}
          spacing={24}
          verticalSpacing={24}
          breakpoints={[
            { maxWidth: "lg", cols: 2, spacing: 24, verticalSpacing: 24 },
            { maxWidth: 700, cols: 1 },
          ]}
        >
          {data.map((card, index) => (
            <PriceCard key={index} {...card}/>
          ))}
        </SimpleGrid>

        <Center mt={24}>
          <Text align="center" c="dimmed">
            Студенты могут вернуть до 13% стоимости курса, оформив налоговый вычет
          </Text>
        </Center>

      </Container>
    </section>
  )
}
