import { Flex, Loader, createStyles } from "@mantine/core"
import React from "react"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}))

// ----------------------------------------------------------------------

export function LoadingScreen(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <Flex justify="center" align="center" className={classes.loader}>
      <Loader size="xl"/>
    </Flex>
  )
}
